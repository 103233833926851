

* {
  margin: 0;
  /* font-family: 'Roboto Slab', serif ; */
  font-family: TTFors,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji !important;
}

html {
  height: 100%;
  overflow-x: hidden;
}
*::-webkit-scrollbar {
  /*chrome*/
  display: none;
}
body {
  overflow-x:hidden;
}
*{
  /*firefox*/
  margin: 0;
  scrollbar-width: none;
}
@media only screen and (max-width: 980px) {
  body {
    font-size:10px;
    overflow-x:hidden;
  }
}
 