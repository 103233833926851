.basicDetail__image {
  width: 58%;
  object-fit: contain;
  margin-left: 50px;
}
form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.basic__details{
    background-color: #02143C;
    display: flex;
    flex-direction: column;
    padding: 35px;
    max-height: 500px;
    width: 240px;
    margin-top: 40px;
    border-radius: 10px;
    box-shadow:
    0 0.3px 1.1px rgba(0, 0, 0, 0.146),
    0 0.8px 2.7px rgba(0, 0, 0, 0.217),
    0 1.7px 5px rgba(0, 0, 0, 0.273),
    0 2.9px 8.9px rgba(0, 0, 0, 0.324),
    0 4.9px 16.7px rgba(0, 0, 0, 0.381),
    0 10px 40px rgba(0, 0, 0, 0.48);
    height: 500px;
    padding-top: 10px;
}
.basicDetail{
    display: flex;
    height: auto;
}
.fullName, select {
  width: 220px;
  padding: 5px 6px;
  margin: 10px 10px;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  outline: none;
}

.basicButton { 
  background-color: #FF5E00;
  border-radius: 10px;
  padding: 7px;
  color: white;
  font-size: 13px;
  border: 0px solid black;
  width: 165px;
  cursor: pointer;
  position: relative;
  bottom: 60px;
  font-weight: 400;
  box-shadow: 0px 5px 10px black;
  }
  .basicButton:hover{
    transform: scale(1.08);
  }
  .text{
    color: #E1E5EE;
    text-align: center;
    margin-bottom:30px;
  }
  .text::after{
    content: "";
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #E61212;
    height: 4px;
    width: 70px;
    display: block;
    border: 30px;
    border-radius: 300px;
    margin-top: 5px;
    margin-left: 80px;
  }
  .head{
    color: #E1E5EE;
  }
  ::placeholder{
    color: #a1a1a1;
    font-size: 15px;
    font-weight: 10px;
  }
  .nb, select {
    width: 150px;
  }
  .mobile__number {
    display: flex;
    align-items: center;
    margin-left: 10px;
  }
  .code {
    height: 18px;
    width: 37px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    border: 1px solid #E1E5EE;
    border-radius: 4px;
    z-index: 1;
    font-size: 13px;
    padding: 5px;
  }
  ::-webkit-code{
  height: 10px;
  width: 37px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #E1E5EE;
  border-radius: 4px;
  z-index: 1;
  }
  .phone__number{
    margin-left: -5px;
  }
  select{
    width: 210px;
  }

  @media only screen and (max-width: 980px){
    .basicDetail {
      display: flex;
      height: auto;
      flex-direction: column;
      text-align: center;
      align-items: center;
  }
  .basicDetail__image {
    width: 90%;
    object-fit: contain;
    margin-left: 0;
    margin-top: 40px;
  } 
  .basic__details {
    max-height: 430px;
    height: 430px;
  }
  .code {
    height: 17px;
    padding: 5px;
}
.text{
  font-size: 20px;
}
.head {
  color: #E1E5EE;
  font-size: 13px;
}
}