.header {
  background-color: transparent;
  display: flex;
  align-items: center;
  z-index: 100;
  font-family: "Roboto Slab", serif;
  justify-content: center;
  width: 95%;
  margin: 0 auto;
}
.expandM{
  transition: all 0.5s;
  padding-top: 3px;
}
.expandL{
  transition: all 0.5s;
  padding-top: 3px;
}
.dNone {
  display: none !important;
}
.header__logo {
  width: 50px;
  margin: 5px;
  object-fit: contain;
}

.header__link {
  text-decoration: none;
  color: #02143c;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header__option {
  margin-left: 10px;
  margin-right: 20px;
}

.header__nav {
  width: 76%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #02143c;
}

.header__button {
  background-color: #02143c;
  border-radius: 10px;
  padding: 7px;
  color: white;
  font-size: 11px;
  box-shadow:  -3px -3px 5px #ffffff70, 3px 3px 15px #00000070;
  border: 0px solid #02143c;
  width: 90px;
  cursor: pointer;
  
}
.header__button:hover {
   transform: scale(1.08);
}
.header__title {
  color: #02143c;
  font-family: "Roboto Slab", serif;
  font-size: 20px;
  height: 40px;
  width: 170px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-weight: 800;
}
.hand {
  cursor: pointer;
}



.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  position: absolute;
  background-color: #f9f9f9;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
  transition: all 0.5s;
}

.dropdown-content a {
  color: #02143c;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
}
/* .dropdown-content a:hover {background-color: #f1f1f1}
.dropdown:hover .dropdown-content {
  display: block;
} */
.menu {
  display: none;
}

@media only screen and (max-width: 980px) {
  .header{
    justify-content: space-between;
  }
  .menu {
    display: block;
  }
  .headmenu{
    transition: all 0.5s;
    transform: translate3d(0,0,0) !important;
  }
  .header__nav {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: flex-start;
    color: #02143c;
    flex-direction: column;
    position: absolute;
    z-index: 99999;
    top: 45px;
    margin-top: 10px;
    background-color: white;
    height: 201px;
    border: 0 solid;
    border-radius: 10px;
    transform: translate3d(0,-140%,0);
    transition: all 0.5s;
}
.header__link {
  color: black !important;
}
.home__heroImage {
  display: block;
  text-align: center;
}
.home__image {
  width: 90%;
  margin-left: 0;
}
.header__button {
  background-color: #02143c;
  border-radius: 10px;
  padding: 7px;
  color: white;
  font-size: 11px;
  box-shadow: 0px 5px 10px black;
  border: 0px solid #02143c;
  width: 200px;
  cursor: pointer;
}
.active{
  display: block;
}
}