.bank {
  display: flex;
  height: 80vh;
  justify-content: space-around;
  background-color: #F9F9F9;
}
.bank__image__bank{
  width: 130px;
  object-fit: contain;
  padding: 8px;
  height: 50px;
  border: 0 solid #E1E5EE;
  box-shadow: 0 5px 10px #C0C9DD;
  margin: 5px;
  margin-top: 10px;
  background-color: white;
}
.bank__image {
  display: flex;
}
.bank__text{
  color: #02143C;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  width: 330px;
}
.bank__images{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.bank__title {
  font-size: 30px;
  margin-bottom: 20px;
}
.bank__list{
  font-size: 25px;
}
@media only screen and (max-width: 980px){ 
  .bank {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  .bank__images{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
  }
  .bank__title {
    font-size: 20px;
    margin-bottom: 20px;
}
.bank__list {
  font-size: 15px;
}
.bank__image__bank {
  width: 55px;
  height: 30px;
}
.bank__text {

  width: auto;
}
}