.policy__content {
  text-align: center;
  margin-top: 40px;
}
.policy__content__heading{
  font-size: 30px;
  margin-top: 30px;
  padding: 20px;
}
.policy__logo{
    width: 50px;
    margin: 5px;
    object-fit: contain;
}
.policy__title{
    color: #02143c;
  font-size: 40px;
  height: 40px;
  width: 400px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  font-weight: 800;
}
.policy__content__logo{
  display: flex;
  width: 400px;
  align-items: center;
  margin: 0 auto;
}
.policy {
  width: 80%;
  margin: 0 auto;
}
.content__text{
  text-align: justify;
  text-justify: inter-word;
  color: #373737;
  width: 95%;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;
  font-weight: 600;
}
.info{
    margin-top: 20px;
}


.personalinfo__heading {
  margin-top: 20px;
  margin-left: 30px;
}
.mobile__header{
  display: none;
}

@media only screen and (max-width: 980px){
  .mobile__header{
    display: block;
  }
  .policy__content__logo {
    display: none; 
  }
  .policy__content {
    margin-top: 0px;
}
body {
  font-size: 13px !important;
}
.personalinfo__heading {
  margin-left: 6px;
}
}