.loanAgainstProperty__image__logo{
  width: 45%;
  object-fit: contain;
  margin-right: 30px;
}
.loanAgainstProperty__image{
  text-align: center;
  margin-top: 15px;
  display: flex;
}
.loanAgainstProperty__content{
  background-color: #02143C;
}
.loanAgainstProperty{
  width: 100%;
  margin: 0 auto;
}
.loanAgainstProperty__heading{
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
  text-align: justify;
  text-justify: inter-word;

}
.loanAgainstProperty__heading__title{
    color: #02143C;
}
.loanAgainstProperty__heading__texts{
    padding: 10px;
    margin-left: 10px;
    font-weight: 600;
    color: #373737;
}
li {
  font-weight: 500 !important;
}
.loanAgainstProperty__image__heading{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 55%;
}
.loanAgainstProperty__image__button{
    border: 0 solid;
    border-radius: 30px;
    width: 200px;
    padding: 10px;
    background-color: #FF5E00;
    box-shadow: 0 0 10px #C0C9DD;
    color: white;
    font-size: 20px;
}
.loanAgainstProperty__image__button:hover{
  transform: scale(1.08);
  cursor: pointer;
}
.loanAgainstProperty__image__heading__title{
    color: black;
    font-size: 30px;
    width: 70%;
    line-height: 3rem; 
}
.loanAgainstProperty__image__heading__text{
    color: #02143C;
    padding: 10px;
    font-size: 20px;
}
.one {
  padding-top: 20px;
  color: #373737;
}
.questions {
  margin-left: 10px;
}

@media only screen and (max-width: 980px){
  .loanAgainstProperty__image{
    text-align: center;
    margin-top: 10px;
    display: flex;
    flex-direction: column-reverse;
  }
  .loanAgainstProperty__image__logo{
    width: 100%;
    object-fit: contain;
    margin-right: 0px;
  }
  .loanAgainstProperty__image__heading{
    width: 100%;
  }
  .loanAgainstProperty__image__heading__title{
    font-size: 20px;
    width: 100%;
    line-height: 1.5rem; 
    margin-top: 20px;
}
.loanAgainstProperty__heading__title{
  color: #02143C;
  font-size: 19px;
  text-align: center;
}
.loanAgainstProperty__heading__texts{
  padding: 10px;
  margin-left: 10px;
  font-weight: 600;
  color: #373737;
  font-size: 13px;
}
}