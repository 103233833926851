.title{
  font-size: 25px;
  text-decoration: solid;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
  font-weight: 800;
}
.title::after{
  content: "";
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #E61212;
  height: 5px;
  width: 4%;
  display: block;
  border: 30px;
  border-radius: 300px;
  margin-top: 5px;
}
.mb-5{
  margin-top: 15px;
}

@media only screen and (max-width: 980px){
  .title::after{
    content: "";
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    background-color: #E61212;
    height: 5px;
    width: 16%;
    display: block;
    border: 30px;
    border-radius: 300px;
    margin-top: 5px;
  }
}