.businessLoan__image__logo {
  width: 40%;
  object-fit: contain;
}
.businessLoan__image {
  text-align: center;
  margin-top: 15px;
  display: flex;
}
.businessLoan__content {
  background-color: #02143c;
}
.businessLoan {
  width: 100%;
  margin: 0 auto;
}
.businessLoan__heading {
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
  text-align: justify;
  text-justify: inter-word;

}
.businessLoan__heading__title {
  color: #02143c;
}
.businessLoan__heading__texts {
  padding: 20px;
  font-weight: 600;
  color: #373737;
}
.businessLoan__image__heading {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 50%;
}
.businessLoan__image__button {
  border: 0 solid;
  border-radius: 30px;
  width: 200px;
  padding: 10px;
  background-color: #ff5e00;
  box-shadow: 0 0 10px #c0c9dd;
  color: white;
  font-size: 20px;
}
.businessLoan__image__button:hover{
  transform: scale(1.08);
  cursor: pointer;
}
.businessLoan__image__heading__title {
  color: black;
  font-size: 30px;
  width: 70%;
  line-height: 3rem;
}
.businessLoan__image__heading__text {
  color: #02143c;
  padding: 20px;
  font-size: 20px;
}
.business__list{
  padding-top: 10px;
}

@media only screen and (max-width: 980px){
  .businessLoan__image {
    text-align: center;
    margin-top: 10px;
    display: flex;
    flex-direction: column-reverse;
  }
  .businessLoan__image__logo {
    width: 100%;
    object-fit: contain;
  }
  .businessLoan__heading__title {
    font-size: 20px;
    text-align: center;
  }
  .businessLoan__image__heading {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  .businessLoan__image__heading__title {
    margin-top: 20px;
    font-size: 20px;
    width: 100%;
    line-height: 1.5rem;
  }
  .businessLoan__heading__texts {
    padding: 10px;
    font-weight: 600;
    color: #373737;
    font-size: 13px;
  }
}