
.home__heroImage {
  display: flex;
}
.home__image {
  width: 50%;
  object-fit: contain;
  margin-left: 30px;
  margin-top: 30px;
}
.home__heroImage {
  margin-top: 10px;
  max-height: auto;
  margin-bottom: 20px;
}
.home__loans {
  height: 545px;
  background-color: #dee6fe;
  padding-top: 5px;
}
.home__process {
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 40px;
  margin-bottom: 40px;
}
.home__loans__card {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 80%;
  margin: 0 auto;
  margin-top: 20px;
}
.home__process__left {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: auto;
}
.process__image {
  width: 200px;
  object-fit: contain;
  height: 120px;
  margin: 10px;
  box-shadow: 0 0 10px 5px #e1e5ee;
  padding: 15px;
}
.home__process__right {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  color: #02143c;
  height: auto;
}
.home__process__right > h2 {
  color: #02143C;
}
.home__process__middle {
  display: flex;
  height: auto;
  width: 35px;
}
.home__WhyUs {
  height: auto;
  padding: 5px;
  background-color: #dee6fe;
}
.home__WhyUs__content {
  display: flex;
  justify-content: space-evenly;
  height: 600px;
}
.home__WhyUs__content__image {
  object-fit: contain;
  width: 140px;
  padding: 20px;
}

.home__WhyUs__content__left, .home__WhyUs__content__right {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 570px;
  justify-content: space-around;
}
.home__WhyUs__content__middle {
  display: flex;
  top: 10px;
  width: 300px;
  height: 570px;
  align-items: center;
  justify-content: center;
}
.home__WhyUs__content__text{
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 300px;
}
.whyus__text{
  margin-top: 6px;
}
.m22 {
  padding: 0;
}
.loans__distribution{
  display: flex;
  background-color: #FF5E00;
  flex-direction: column;
  padding: 20px;
  width: 480px;
  height: 140px;
  justify-content: center;
  align-items: center;
}
.bank__partners{
  display: flex;
  background-color: #FF5E00;
  flex-direction: column;
  width: 480px;
  padding: 20px;
  height: 140px;
  justify-content: center;
  align-items: center;
}
.happy__customers{
  display: flex;
  background-color: #02143C;
  width: 500px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  font-size: 35px;
  color: #E1E5EE;
}
.happy__customers__subtext{
  font-size: 20px;
}
.achieve {
  display: flex;
 
}

.loans__distribution__text, .bank_partners__text{
  font-size: 72px;
}
.getintouch{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #02143c;
  align-items: center;
  padding: 20px;
  
}
.getintouch__title{
  color: white;
  font-size: 33px;
}

.getintouch__button{
background-color: #FF5E00;
border-radius: 10px;
padding: 11px;
color: white;
font-size: 13px;
border: 0px solid black;
width: 200px;
cursor: pointer;
font-weight: 400;
box-shadow: 0px 5px 10px black;
}
.getintouch__button:hover{
  transform: scale(1.08);
}
.home__WhyUs__content__middle__image{
  height: 160px;
  object-fit: contain;
}


@media only screen and (max-width: 980px){
  .home__loans {
    height: auto;
    background-color: #dee6fe;
    padding-top: 5px;
    margin-top: 0px;
  }
  .home__loans__card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    margin: 0;
  }
  .process__image {
    width: 130px;
    object-fit: contain;
    height: 120px;
    margin: 10px;
    padding: 5px;
  }
  .home__process__middle {
    display: none;
  }
  .home__WhyUs__content__middle {
    display: none;
  }
  .home__WhyUs__content {
    display: block;
    height: auto;
    margin-top: 30px;
  }
  .home__WhyUs__content__text {
    width: 338px;
    margin: 15px;
}
.home__WhyUs__content__left, .home__WhyUs__content__right {
  height: auto;
}
  .home__WhyUs__content__image {
    object-fit: contain;
    width: 330px;
    height: 200px;
    border: 0px solid;
    box-shadow:  -3px -3px 5px #ffffff70, 3px 3px 15px #00000070;
    background-color: white;
    border-radius: 20px;
    padding: 0 !important;
  }
  .achieve {
    display: flex;
    flex-direction: column;
}
.loans__distribution {
  width: auto;
  height: 50px;
}
.happy__customers{
  width: auto;
  height: 100px;
  font-size: 20px;
}
.bank__partners{
  width: auto;
  height: 50px;
}
.loans__distribution__text, .bank_partners__text{
  font-size: 36px;
}
.getintouch{
  text-align: center;
}
.getintouch__title{
  color: white;
  margin-bottom: 5px;
  font-size: 18px;
}
.getintouch__button{
  width: 160px;
  }

}