input[type="range"] {
  height: 25px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  border: 10px;
  background: transparent;
}

input[type="range"]:focus {
  outline: none;
}

input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000;
  background: #e1e5ee;
  border-radius: 5px;
  border: 0px solid #000000;
}

input[type="range"]::-webkit-slider-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #e1e5ee;
  height:18px;
  width: 4px;
  border-radius: 25px;
  background: #e1e5ee;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -7px;
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: #e1e5ee;
}

input[type="range"]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  box-shadow: 0px 0px 0px #000000;
  background: #e1e5ee;
  border-radius: 5px;
  border: 0px solid #000000;
}

input[type="range"]::-moz-range-thumb {
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #e1e5ee;
  height: 18px;
  width: 4px;
  border-radius: 25px;
  background: #e1e5ee;
  cursor: pointer;
}

input[type="range"]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]::-ms-fill-lower {
  background: #e1e5ee;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}

input[type="range"]::-ms-fill-upper {
  background: #e1e5ee;
  border: 0px solid #000000;
  border-radius: 2px;
  box-shadow: 0px 0px 0px #000000;
}

input[type="range"]::-ms-thumb {
  margin-top: 1px;
  box-shadow: 0px 0px 0px #000000;
  border: 1px solid #e1e5ee;
  height: 18px;
  width: 18px;
  border-radius: 25px;
  background: #e1e5ee;
  cursor: pointer;
}

input[type="range"]:focus::-ms-fill-lower {
  background: #e1e5ee;
}

input[type="range"]:focus::-ms-fill-upper {
  background: #e1e5ee;
}



.value-button {
 
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: flex;
  justify-content: center;
}

.value-button:hover {
  cursor: pointer;
}



/* form #input-wrap {
  margin: 0px;
  padding: 0px;
} */

input#number {
  text-align: center;
  border: none;
  margin: 0px;
  width: 40px;
  height: 33px;
  border-radius: 50px;
  outline: none;
  background-color: transparent;
  font-size: 17px;
  font-weight: bold;
}

input[type="number"]{
  -moz-appearance: textfield;
}


input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.loanCalculator{
  background-color: #02143C;
  display: flex;
  flex-direction: column;
  padding: 50px;
  align-items: center;
  max-height: 450px;
  border-radius: 10px;
  box-shadow:0px 5px 10px black;
  margin-top: 15px;
  width: 240px;
  padding-top: 20px;
  height: 420px;
  padding-left: 30px;
  padding-right: 30px;
  letter-spacing: 0.8px;
}

.loanCalculator,form > p {
  color: #E1E5EE;
  font-size: 18px;
}
.barvalue {
  font-size: 45px;
  height: 100px;
  width: 215px;
  border-radius: 11px;
  background-color: #E1E5EE;
  color: #02143C;
  font-weight: 800;
  text-align: center;
  outline: none;
  border: none;
}
.loanCalculator__amount {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.loanCalculator__percent {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e1e5ee;
  color: black;
  border-radius: 18px;
  width: 100px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.loanCalculator__payment {

  background-color: #e1e5ee;
  color: black;
  border-radius: 11px;
  width: 130px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.emi__value {
  width: 100% !important;
}
form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
}
.submitButton { 
background-color: #FF5E00;
border-radius: 10px;
padding: 11px;
color: white;
font-size: 13px;
border: 0px solid black;
width: 100px;
cursor: pointer;
position: relative;
bottom: 23px;
font-weight: 400;
box-shadow: 0px 5px 10px black;
}
.submitButton:hover{
  transform: scale(1.08);
}
.perInput{
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
.perInput::after {
  content: '%';
}
.perInput input {
  border: none;
  padding:0px !important;
  outline: none;
  width: 25px !important;
  height: 36px !important;
}
.perror {
  font-size: 10px;
  color: red;
  padding: 5px;
  position: absolute;
  top: 350px;
  border-radius: 25px;
  border: 1px solid transparent;
  background-color: transparent;
  display: none;
}
.yerror {
  font-size: 10px;
  color: red;
  padding: 5px;
  position: absolute;
  top: 440px;
  display: none;
  border-radius: 25px;
  border: 1px solid transparent;
  background-color: transparent;
}
.error {
  display: block !important;
}
@media only screen and (max-width: 980px) {
  body {
    font-size:13px;
  }
  .loanCalculator{
    background-color: #02143C;
    display: flex;
    flex-direction: column;
    padding: 65px;
    align-items: center;
    max-height: 330px;
    border-radius: 10px;
    box-shadow:0px 5px 10px black;
    margin-top: 15px;
    width: 180px;
    padding-top: 20px;
    height: 450px;
    padding-left: 30px;
    padding-right: 30px;
    letter-spacing: 0.8px;
  }
  .loanCalculator,form > p {
    color: #E1E5EE;
    font-size: 12px;
  }
  .barvalue {
    font-size: 35px;
    height: 50px;
    width: 150px;
    border-radius: 6px;
    background-color: #E1E5EE;
    color: #02143C;
    font-weight: 800;
    text-align: center;
    outline: none;
    border: none;
  }
  input[type="range"] {
    height: 25px;
    -webkit-appearance: none;
    margin: 13px 0;
    width: 100%;
    border: 10px;
    background: transparent;
  }
}