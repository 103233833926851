.needus__box {
background-color: #FFFFFF;
display: flex;
flex-direction: column;
padding: 20px;
align-items: center;
max-height: 700px;
border-radius: 10px;
box-shadow:  -3px -3px 5px #ffffff70, 3px 3px 15px #00000070;
width: 600px;
height: 600px;
margin-left: 200px;
position: relative;
top: -60px;
}
.needus {
  height: auto;
  background-color: #dee6fe;
  display: flex;
  justify-content: baseline;
  padding-bottom: 20px;
}
.needus__box__image{
  width: 240px;
  object-fit: contain;
}
.needus__box__TITLE {
  font-size: 25px;
  width: 255px;
}
.needus__box__text {
  font-size: medium;
  font-style: normal;
  width: 250px;
  text-align: center;
  font-family: "Roboto Slab", serif;
  color: #02143C;
  margin-bottom: 20px;
}
.icon { 
color: green;
border: 1px solid gray;
border-radius: 25px;
padding: 2px;
background-color: #FFFFFF;
margin-right: 7px;
}
.iconsWhole{
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #02143C;
  color: white;
  border-radius: 15px;
  width: 115px;
  margin-top: 10px;
  box-shadow:  -3px -3px 5px #ffffff70, 3px 3px 15px #00000070;
  margin-bottom: 10px;
}
.iconsWholes{
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background-color: #207F70;
  color: white;
  border-radius: 15px;
  width: 180px;
  margin-top: 10px;
  box-shadow:  -3px -3px 5px #ffffff70, 3px 3px 15px #00000070;
  margin-bottom: 10px;
}
.iconsWholes:hover {
  transform: scale(1.08);
}
.iconsWhole:hover {
  transform: scale(1.08);
}
.icons {
  color: #FFFFFF;
border-radius: 25px;
padding: 2px;
background-color: #02143C;
margin-right: 7px;
}
.needus__text{
    display: flex;
    flex-direction: column;
    margin-left: 100px;
    margin-top: 50px;
}
.needus__text__head{
   margin-top: 40px;
   font-size: 37px;
}
.needus__text__subtext{
  font-size: 20px;
  margin-right: 50px;
  margin-top: 30px;
}
.needus__text__list{
  font-size: 25px;
  margin-top: 20px;
}

.needus__text > ul {
  list-style: none;
}

.needus__text > ul li:before {
  content: '✓';
  margin-right: 10px;
}

@media only screen and (max-width: 980px){
  .needus__box {
    background-color: #FFFFFF;
    display: flex;
    flex-direction: column;
    padding: 20px;
    align-items: center;
    max-height: 700px;
    border-radius: 10px;
    width: 280px;
    height: auto;
    margin-left: 0px;
    position: relative;
    top: 0;
    margin-bottom: 40px;
    }
    .needus {
      height: auto;
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
  }
  .needus__text__head{
    margin-top: 0px;
    font-size: 30px;
 }
 .needus__text {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
  margin-top: 30px;
  margin-bottom: 30px;
}
.needus__text__subtext{
  font-size: 15px;
  margin-bottom: 20px;
}
.needus__text__list {
  font-size: 14px;
}
.needus__box__image {
  width: 300px;
  height: 200px;
}
.needus__box__TITLE {
  font-size: 20px;
  text-align: center;
}
.needus__box__text {
  font-size: small;
  width: auto;
  width: 300px;
}
.iconsWhole {
  padding: 7px;
  border-radius: 10px;
  width: 79px;
}
.icon {
  color: green;
  border: 1px solid gray;
  border-radius: 25px;
  padding: 2px;
  background-color: #FFFFFF;
  margin-right: 7px;
  width: 13px !important;
  height: 13px !important;
}
.icons {
  padding: 1px;
  width: 18px !important;
  height: 18px !important;
}
.iconsWholes {
  padding: 10px;
  width: 128px;
}
.needus__text__subtext {
  margin-right: 0px;
}
}