
.contactus2{
  display: flex;
flex-direction: column;
text-align: center;
justify-content: center;
align-items: center;
}
.contactus2__text{
    color: #02143C;
    text-align: center;
    width: auto;
    font-size: 20px;
    font-weight: 800;
}
.contactus2__image{
  width: 60%;
}
.contactus2__text__heading{
    text-align: center;
    font-size: 25px;
    margin-top: 15px;
}
/* .contactus2 {
   position: absolute;
   top: 50%;
   left: 80px;
} */
.c__icon1{
    background-color: #FF5E00;
    padding: 10px;
    border-radius: 50px;
    margin: 5px;
    color: white;
    width: 3em !important;
    height: 3em !important;
    margin-left: 20px;
}
.contactus2__icons{
  display: flex;
  justify-content: space-evenly;
  height: 50vh;
}
.contactus2__icons__main {
  display: flex;
  flex-direction: column;
  margin: 50px;
}
.contactus2__icons__texts{
  width: 200px;
  margin-top: 30px;

}

@media only screen and (max-width: 980px){
  .contactus2__text {
    width: 80%;
    font-size: 13px;
    margin-top: 20px;
  }
  .contactus2__icons {
    display: flex;
    justify-content: center;
    height: auto;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.contactus2__icons__main {
  display: flex;
  flex-direction: column;
  margin: 20px;
  align-items: center;
}
.contactus2__icons__texts {
  width: 100%;
  margin-top: 30px;
  text-align: center;
}
.c__icon1 {
  background-color: #FF5E00;
  padding: 13px;
  border-radius: 50px;
  margin: 0px;
  color: white;
  width: 3em !important;
  height: 3em !important;
  font-size: 1rem !important;
}
.contactus2 {

  margin-top: 40px;
}
.contactus2__image{
  width: 90%;
}
}






