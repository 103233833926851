
.partner__image{
  width: 35%;
  object-fit: contain;
  margin-top: 50px;
 
}
.partner__heading{
  display: flex;
  flex-direction: column;
  color:black;
}
.partner__imageTag{
  display: flex;
 justify-content: space-around;
  align-items: center;
}
.leverage{
  color: #000;
  padding: 40px;
  background-color: #dee6fe;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row-reverse;
}
.leverage__heading{
  color: black;
  font-size: 30px;
  width: 50%;
}
.leverage__list{
  padding: 20px;
  font-size: 20px;
  font-weight: 800;
  width: 50%;
}
.leverage__lists{
  padding: 20px;
}
.partner__heading__text{
  margin-bottom: 10px;
  font-size: 30px;
}
.partner__heading__list{
  font-size: 25px;
}
.leverage__heading{
  margin-bottom: 10px;
}

.benefits__heading__text{
  color: #02143c;
  font-size: 25px;
  text-align: center;
  padding: 20px;
}

.benefits__image__pic{
  width: 100%;
  object-fit: contain;
  height: 100%;
}
.benefits__images{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  height: 40vh;
}
.benefits__image {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.benefits__image__pic{
  border: 0px solid black;
  box-shadow: 0 50px 90px 0 rgb(77 108 217 / 16%);
  border-radius: 10px;
  padding: 10px;
  height: 60%;
  width: 280px;
}
.benefits__image__text{
  font-size: 18px;
  padding: 20px;
  font-weight: 800;
  color: #02143c;
}
.leverage > ul {
  list-style: none;
}

.leverage > ul li:before {
  content: '✓';
  margin-right: 10px;
}
.gas{
 height: 60%;
}
.partners__heading{
  color: #02143c;
  font-size: 25px;
  text-align: center;
  padding: 20px;
  margin-bottom: 50px;
}
.partners__image__pic{
  width: 80%;
  object-fit: contain;
  padding: 20px;
}
.partners__images{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding-left: 20px;
  margin-bottom: 30px;
}
.partners__image {
  display: flex;
  flex-direction: column;
  text-align: center;
  height: 300px;
}
.partners__image__pic{
  border: 0px solid black;
  box-shadow: 0 50px 90px 0 rgb(77 108 217 / 16%);
  border-radius: 10px;
  height: 60%;
}
.partners__image__text{
  font-size: 18px;
  padding: 20px;
  font-weight: 800;
  color: #02143c;
}
.s{
  height: 40% !important;
  width: 200px;
}
.d{
  height: 40% !important;
  width: 200px;
}
.container{
  max-width: 800px;
  background: #fff;
  width: 800px;
  padding: 25px 40px 10px 40px;
  box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
}
.contactusform{
  display: flex;
  align-items: center;
  justify-content: center;
  
  padding: 40px;
  background: linear-gradient(115deg, #56d8e4 10%, #9f01ea 90%);
}
.container .container__text{
  text-align: center;
  font-size: 35px;
  font-weight: 600;
}
.container{
  border-radius: 30px;
  box-shadow:  -3px -3px 5px #ffffff70, 3px 3px 15px #00000070;
}
.container form{
  padding: 0px 0 0 0;
}
.container form .form-row{
  display: flex;
  margin: 32px 0;
}
form .form-row .input-data{
  width: 100%;
  height: 40px;
  margin: 0 60px;
  position: relative;
}
form .form-row .textarea{
  height: 70px;
}
.input-data input,
.textarea textarea{
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 17px;
  border-bottom: 2px solid rgba(0,0,0, 0.12);
  outline: none;
}
.input-data input:focus ~ label, .textarea textarea:focus ~ label,
.input-data input:valid ~ label, .textarea textarea:valid ~ label{
  transform: translateY(-20px);
  font-size: 14px;
  color: #3498db;
  border: none !important;
  outline: none;
}
.textarea textarea{
  resize: none;
  padding-top: 10px;
}
.input-data label{
  position: absolute;
  pointer-events: none;
  bottom: 10px;
  font-size: 16px;
  transition: all 0.3s ease;
  border: none !important;
}
.textarea label{
  width: 100%;
  bottom: 40px;
  background: #fff;
  border: none !important;
}
.input-data .underline{
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 100%;
}
.input-data .underline:before{
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  background: #3498db;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}
.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before,
.textarea textarea:focus ~ .underline:before,
.textarea textarea:valid ~ .underline:before{
  transform: scale(1);
  border: none !important;
  outline: none;
}
.submit-btn .input-data{
  overflow: hidden;
  height: 45px!important;
  width: 200px!important;
  border-radius: 20px;
}
.submit-btn .input-data .inner{
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  background: -webkit-linear-gradient(right, #56d8e4, #9f01ea, #56d8e4, #9f01ea);
  transition: all 0.4s;
}
.submit-btn .input-data:hover .inner{
  left: 0;
}
.submit-btn .input-data input{
  background: none;
  border: none;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  cursor: pointer;
  position: relative;
  z-index: 2;
}
@media (max-width: 980px) {
  .container .text{
    font-size: 30px;
  }
  .container form{
    padding: 0px;
  }
  .container form .form-row{
    display: block;
  }
  form .form-row .input-data{
    margin: 15px 0!important;
  }
  .submit-btn .input-data{
    width: 100% !important;
  }
}
.plabel{
  margin-bottom: 30px;
  margin-left: 10px;
}
@media only screen and (max-width: 980px) {
  body {
    font-size: 10px;
  }
  .partner__imageTag {
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column-reverse;
  }
  .ac {
    padding-top: 41px !important;
  }
  .partner__image{
    width: 60%;
    margin-top: 0px;
    padding: 10px;
  }
  .partner__heading__text{
    font-size: 20px;
  }
  .partner__heading__list{
    font-size: 15px;
  }
  .partner__heading {
    margin-bottom: 20px;
  }
  .leverage{
    flex-direction: column;
  }
  .leverage__heading{
    width: 100%;
  }
  .leverage__list {
    width: 100%;
}
.leverage__lists {
  padding: 10px;
}
.leverage {
  padding: 20px;
}
.benefits__images{
  flex-direction: column;
  height: auto;
}
.benefits__image__pic{
  width: 275px;
  height: 66%;
  padding: 0px;
}
.gas{
  height: 200px;
 }
 .benefits__image {
  align-items: center;
}
.partners__images{
  display: flex;
  flex-direction: column;
}
.partners__image {
  align-items: center;
}
.s,.d {
  height: 50% !important;
  width: 200px;
  padding: 40px;
}
.partners__images {
  padding-left: 0px;
}
.partners__heading{
  margin-bottom: 0px;
}
.container form .form-row{
  margin: auto;
}
.submit-btn{
  width: 50%;
}
}
