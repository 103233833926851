.card {
  background-color: #02143c;
  display: flex;
  flex-direction: column;
  padding: 15px;
  align-items: center;
  max-height: 395px;
  border-radius: 10px;
  margin: 0 auto;
  margin-top: 15px;
  width: 20%;
  height: 270px;
  color: white;
  box-shadow: 0px 5px 10px black;
}
.card__button {
  background-color: #ff5e00;
  border-radius: 10px;
  padding: 8px;
  color: white;
  font-size: 13px;
  border: 0px solid black;
  width: 100px;
  cursor: pointer;
  font-weight: 400;
  box-shadow: 0px 5px 10px black;
  margin-top: 120px;
  /* position: relative;
  bottom: -115px; */
}
.card__button:hover{
  transform: scale(1.08);
}
.card__title {
  font-size: 16px;
  text-align: center;
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #8EC3CB;
}
.card__text {
  font-size: 15px;
  text-align: center;
  height: 100px;
}
.m3 {
  margin-top: 100px;
}

@media only screen and (max-width: 980px){
  .card__button {
    margin-top: 90px;
  }
  .card {
    background-color: #02143c;
    display: flex;
    flex-direction: column;
    padding: 15px;
    align-items: center;
    max-height: 240px;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 30px;
    margin-bottom: 40px;
    width: 230px;
    height: 290px;
    color: white;
    box-shadow: 0px 5px 10px black;
  }

}

@media only screen and (min-width: 980px) and (max-width: 1262px){
  .home__loans__card {
    display: grid;
    grid-template-columns: auto auto;
    width: 95%;
    grid-column-gap: 50px;
    padding-bottom: 40px;
    grid-row-gap: 50px;
}
.home__loans {
  height: auto;
  background-color: #dee6fe;
  padding-top: 5px;
}
.m3 {
  margin-top: 20px;
}
.card {
  width: 50%;
}
}