.testimonials {
  height: 575px;
  margin-top: 30px;
}
.testimonials__title{
    text-align: center;
}
.testimonials__title__heading {
  font-size: 30px;
  margin-bottom: 10px;
}
.testimonials__title__subhead{
font-size: 25px;
font-family: 'Roboto Slab', serif;
font-size: 25px;
text-decoration: solid;
display: flex;
justify-content: center;
flex-direction: column;
align-items: center;
margin-top: 20px;
}
.testimonials__card__left__card1{
  width: 235px;
  border: 0px solid gray;
  border-radius: 50px;
  padding: 25px;
  height: 91px;
  text-align: center;
  box-shadow: 0 0px 10px #C0C9DD;
}

.testimonials__card{
  display: flex;
  justify-content: space-evenly;
  align-content: center;

}
.testimonials__card__left,.testimonials__card__middle,.testimonials__card__right{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 400px;
}
.testimonials__title__subhead::after {
  content: "";
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  background-color: #E61212;
  height: 5px;
  width: 7%;
  display: block;
  border: 30px;
  border-radius: 300px;
  margin-top: 5px;
  display: flex;
  justify-content: center;
  margin-left: 4px;
}
.testimonials__card__left__card1__subtext{
  color: #FF5E00;
  margin-top: 5px;
  
}
.testimonials__card__left__card1__text{
   
    font-size: 14px;
    color: #777;
}
.m2 {
  margin-top: 60px;
}
.testimonials__card__left__card1__subtext, .testimonials__card__left__card1__subtext__foot{
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-size: 12px;
  margin-right: 15px;
}

@media only screen and (max-width: 980px){
  .testimonials__title__subhead::after {
    width: 13%;
  }
  .testimonials__card__left,.testimonials__card__right{
    display: none;
  }
  .m2 {
    margin-top: 0px;
  }
  .testimonials {
    height: 530px;
    margin-top: 30px;
  }
}