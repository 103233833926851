.footer__logo {
  width: 45px;
  margin: 5px;
  object-fit: contain;
}
a {
  text-decoration: none;
  color: #02143c;
}
.footer__link {
  text-decoration: none;
  color: #02143c;
  display: flex;
  align-items: center;
}
.footer__Title{
  display: flex;
  align-items: center;
}
.footer__title {
  color: #02143c;
  font-size: 20px;
  margin-left: 10px;
  display: flex;
  align-items: center;
}
.section{
  background-color: #dee6fe;
  
}
.footer{
  height: auto;
  width: 90%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
.footer__title{
  font-size: 30px;
  font-weight: 700;
}
.footer__left {
display: flex;
flex-direction: column;
justify-content: space-around;
margin-top: 20px;
width: 60%;
}
.footer__text {
  width: 80%;
  color: #02143c;
  font-weight: 600;
  font-size: 20px;
  margin-left: 40px;
}
.footer__right__top {
  display: flex;
  color: #02143c;
  justify-content: space-evenly;
}
.contactus{
  width: 175px;
  margin-top: 3px;
}
.legal__texts{
  padding: 10px;
}
.contactus__texts{
  font-family: initial;
  margin-top: 20px;
  margin: revert;
}
.footer__right {
  display: flex;
flex-direction: column;
margin-top: 28px;
width: 40%;
}
.footer__right__bottom {
  display: flex;
  justify-content: center;
  width: 100%;
}
.s2 {
  margin-right: 20px;
}

.location {
  display: flex;
  font-size: 15px;
  font-weight: 700;
  color: #02143c;
  margin-bottom: 20px;
}
.icon1 {
  font-size: 2.5rem !important;
  height: 2rem !important;
}
.plink{
  text-decoration: none;
  color: #02143c;
}

.footer__new{
  display: flex;
justify-content: center;
margin-bottom: 20px;
align-items: center;
}
.loans__new{
  display: flex;
  flex-direction: column;
  margin-top: 10px;
}
@media only screen and (max-width: 980px){
  .footer{
    display: flex;
    flex-direction: column;
    height: fit-content;
  }
  .footer__left {
    margin-left: 0px;

  }
  .footer__text {
    width: 320px;
    margin-left: 20px;
  }
  .location {
    width: 300px;
  }
  .footer__right {
  width: 100%;
  height: auto;
  margin-top: 0px;
  margin-left: 0px;

  }
  .footer__left{
    width: 100%;
    align-items: flex-start;
  }
  .footer__text {
    width: 85%;
    margin-left: 0px;
    font-size: 16px;
    padding: 20px;
}
.footer__right__top {
  flex-direction: column;
}
.contactus {
  margin-bottom: 20px;
}

}