.personalloan__image__logo{
  width: 50%;
  object-fit: contain;
  margin-right: 30px;
}
.personalloan__image{
  text-align: center;
  margin-top: 15px;
  display: flex;
  
  height: 80vh;
}
.personalloan__content{
  background-color: #02143C;
}
.personalloan{
  width: 100%;
  margin: 0 auto;
}
.personalloan__heading{
  width: 80%;
  margin: 0 auto;
  margin-top: 50px;
 
}
.personalloan__heading__title{
    color: #02143C;
}
.personalloan__heading__texts{
    padding: 20px;
    text-align: justify;
    text-justify: inter-word;
    letter-spacing: 0.4px;
    font-weight: 600;
    color: #373737;
}
.personalloan__image__heading{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    width: 50%;
}
.personalloan__image__button{
    border: 0 solid;
    border-radius: 30px;
    width: 200px;
    padding: 10px;
    background-color: #FF5E00;
    box-shadow: 0 0 10px #C0C9DD;
    color: white;
    font-size: 20px;
}
.personalloan__image__button:hover{
  transform: scale(1.08);
  cursor: pointer;
}
.personalloan__image__heading__title{
    color: black;
    font-size: 30px;
    width: 70%;
  line-height: 3rem;
}
.personalloan__image__heading__text{
    color: #02143C ;
    padding: 20px;
    font-size: 20px;
}
.personalloan__heading__texts p {
  padding-top: 15px;
 }
 .paragraph {
  padding-top: 0;
}

@media only screen and (max-width: 980px){
  .personalloan__image{
    text-align: center;
    margin-top: 10px;
    display: flex;
    flex-direction: column-reverse;
    height: auto;
    align-items: center;
  }
  .personalloan__image__logo{
    width: 90%;
    object-fit: contain;
    margin-left: 10px;
  }
  .personalloan__image__heading__title{
    color: black;
    font-size: 20px;
    width: 190%;
    line-height: 1.5rem;
    margin-top: 20px;
  }
  .personalloan__heading__title{
    color: #02143C;
    font-size: 20px;
    text-align: center;
  }
  .personalloan__heading__texts{
    padding: 10px;
    text-align: justify;
    text-justify: inter-word;
    letter-spacing: 0px;
    font-weight: 600;
    color: #373737;
    font-size: 13px;
}
}