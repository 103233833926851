.scrollbar {
  height: 686px;
}

.scrollbar_range {
  width: 13px;
  height: 650px;
  border-radius: 50px;
  background-color: #02143c;
  margin-top: 20px;
  min-height: 500px;
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.scrollbar_thumb {
  width: 35px;
  height: 35px;
  background-color: #ff5e00;
  border-radius: 50px;
  position: relative;
  right: 11px;
  top: 41px;
}
.scrollbar_thumb2 {
  width: 35px;
  height: 35px;
  background-color: #ff5e00;
  border-radius: 50px;
  position: relative;
  right: 11px;
  top: 177px;
}
.scrollbar_thumb3 {
  width: 35px;
  height: 35px;
  background-color: #ff5e00;
  border-radius: 50px;
  position: relative;
  right: 11px;
  top: 314px;
}
.scrollbar_thumb4 {
  width: 35px;
  height: 35px;
  background-color: #ff5e00;
  border-radius: 50px;
  position: relative;
  right: 11px;
  top: 450px;
}

@media only screen and (max-width: 980px){
  .scrollbar_range {
    width: 11px;
    height: 550px;
    border-radius: 50px;
    background-color: #02143c;
    margin-top: 20px;
    min-height: 500px;
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    display: none;
  }
  
}